
import { defineComponent } from 'vue';

import { getVideoUrl } from '@/utils/mediaUrl';

import TableList from '../../../components/tables/TableList.vue';
const columns = [
  {
    title: 'File name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Video',
    dataIndex: 'mediaUrl',
    key: 'mediaUrl',
  },
  {
    title: 'face',
    dataIndex: 'faces',
    key: 'faces',
  },
];

const listColumns = [{ title: '만든 사람', key: 'username' }];

export default defineComponent({
  name: 'UploadImage',
  components: { TableList },
  props: {
    apiUrl: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    return {
      columns,
      listColumns,
      //state
      //funcs
      getVideoUrl,
    };
  },
});
